<template>
  <v-container>

    <v-dialog
        v-model="bindTripbitsDialog.isOpen"
        max-width="400"
    >
      <v-card>
        <v-card-title>
          Select Tripbits customer set
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              for customer:
            </v-row>
            <v-row class="font-weight-bold">
              {{ bindTripbitsDialog.customerName }}
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                    :item-text="item => `${item.name} (RM: ${item.routemap})`"
                    item-value="id"
                    label="Tripbits customer set"
                    clearable
                    dense
                    chips
                    small-chips
                    v-model="bindTripbitsDialog.tripbitsId"
                    :items="tripbitsCustomerSets"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="bindTripbitsDialog.isOpen = false"
          >
            Cancel
          </v-btn>
          <v-btn
              @click="bindTripbitsCustomerSet"
              color="blue darken-1"
              text
              :disabled="!bindTripbitsDialog.tripbitsId"
          >
            Bind
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="unbindTripbitsDialog.isOpen"
        max-width="400"
    >
      <v-card>
        <v-card-title>
          Unbind Tripbits customer set
          <span class="font-weight-bold ml-4">
            {{ currentTripbitsCustomerSet(unbindTripbitsDialog.tripbitsId).name }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              for customer:
            </v-row>
            <v-row class="font-weight-bold">
              {{ unbindTripbitsDialog.customerName }}
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="unbindTripbitsDialog.isOpen = false"
          >
            Cancel
          </v-btn>
          <v-btn
              @click="unbindTripbitsCustomerSet"
              color="blue darken-1"
              text
          >
            Unbind
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [10, 25, 50, -1] }"
      :headers="headers"
      :items="customers"
      :single-expand="true"
    >
      <template v-slot:top>
        <v-toolbar
          class="mt-4"
          flat
        >
          <CustomerForm v-on:create="addCustomer" />

          <v-btn
              icon
              outlined
              @click="refreshData"
          >
            <v-tooltip
                bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                >
                  mdi-refresh
                </v-icon>
              </template>
              refresh customer list
            </v-tooltip>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            icon @click="createCompositeCustomer(item.id)"
            :disabled="item.has_same_name_composite_customer"
        >
          <v-tooltip>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
              >
                mdi-plus
              </v-icon>
            </template>
            create composite customer
          </v-tooltip>
        </v-btn>
        <v-btn icon @click="remove(item.id)">
          <v-icon
              color="red"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.name="{ item }">
        <v-row>
        <v-edit-dialog
          @save="save(itemClone, item)"
          @open="itemClone = {...item}"
        >
          {{ item.name }}
          <template v-slot:input>
            <v-text-field
              v-model="itemClone.name"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
        </v-row>
      </template>

      <template v-slot:item.full_name="{ item }">
        <v-edit-dialog
          @save="save(itemClone, item)"
          @open="itemClone = {...item}"
        >
          <div v-if="item.full_name" class="font-weight-black">{{ item.full_name }}</div>
          <div v-else class="font-italic font-weight-thin red--text" v-text="'empty'"/>
          <template v-slot:input>
            <v-text-field
              v-model="itemClone.full_name"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.dg_alias="{ item }">
        <v-edit-dialog
          @save="save(itemClone, item)"
          @open="itemClone = {...item}"
        >
          <div v-if="item.dg_alias" class="font-weight-black">{{ item.dg_alias }}</div>
          <div v-else class="font-italic font-weight-thin red--text" v-text="'empty'"/>
          <template v-slot:input>
            <v-text-field
              v-model="itemClone.dg_alias"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.application_url="{ item }">
        <v-edit-dialog
            @save="save(itemClone, item)"
            @open="itemClone = {...item}"
        >
          <div v-if="item.application_url" class="font-weight-black">
            {{ item.application_url }}
          </div>
          <div v-else class="font-italic font-weight-thin red--text" v-text="'empty'"/>
          <template v-slot:input>
            <v-text-field
                v-model="itemClone.application_url"
                label="Edit"
                single-line
                counter
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.airport="{ item }">
        <v-switch
            v-model="item.is_airport"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.tripbits_customers="{ item }">
        <v-btn
            text
            color="teal accent-4"
            fab
            small
            @click="callBindTripbitsCustomerSetDialog(item.id, item.name)"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-label
                </v-icon>
            </template>
            add tripbits customer set
          </v-tooltip>
        </v-btn>
          <v-chip
              v-for="tripbitsCustomer in item.tripbits"
              :key="tripbitsCustomer.tripbits_id"
              close
              @click:close="callUnbindTripbitsCustomerSetDialog(
                item.id,
                item.name,
                tripbitsCustomer.tripbits_id
              )"
              class="ma-0"
              small
          >
            {{ currentTripbitsCustomerSet(tripbitsCustomer.tripbits_id).name }}
            {{ currentTripbitsCustomerSet(tripbitsCustomer.tripbits_id).routemap
              ? `(RM: ${currentTripbitsCustomerSet(tripbitsCustomer.tripbits_id).routemap})`
              : ''
            }}
          </v-chip>
      </template>

      <template v-slot:item.active="{ item }">
        <v-switch
            v-model="item.is_active"
            @change="save(item, item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import CustomerForm from '../components/CustomerForm.vue';

export default {
  name: 'Customers',
  components: {
    CustomerForm,
  },
  data: () => ({
    itemClone: {},
    customers: [],
    tripbitsCustomerSets: [],
    headers: [
      { text: 'id', value: 'id' },
      { text: 'name', value: 'name' },
      { text: 'full name', value: 'full_name' },
      { text: 'DG alias', value: 'dg_alias' },
      { text: 'tripbits customer sets', value: 'tripbits_customers' },
      { text: 'is airport', value: 'airport' },
      { text: 'is active', value: 'active' },
      { text: 'application url', value: 'application_url' },
      { text: '', value: 'actions', sortable: false },
    ],
    options: {
      itemsPerPage: 10,
      sortBy: ['id'],
      sortDesc: [false],
      mustSort: true,
      multiSort: false,
    },

    bindTripbitsDialog: {
      isOpen: false,
      customerName: '',
      customerId: null,
      tripbitsId: null,
    },

    unbindTripbitsDialog: {
      isOpen: false,
      customerName: '',
      customerId: null,
      tripbitsId: null,
    },
  }),

  created() {
    this.fetchTripbitsCustomerSetList();
    this.fetchCustomers();
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    async refreshData() {
      this.fetchCustomers();

      this.fetchTripbitsCustomerSetList();
    },

    async fetchCustomers() {
      await this.$requestHelpers
        .get('api/admin/customer')
        .then((response) => {
          this.customers = response.data;
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              this.$router.push({ name: 'root' });
            }
          }
        });
    },

    addCustomer(customer) {
      this.customers.push(customer);
    },

    save(itemClone, item) {
      this.$requestHelpers
        .patch(`api/admin/customer/${item.id}`, itemClone)
        .then((response) => {
          Object.assign(item, response.data.result);
        });
    },

    remove(id) {
      const customer = this.customers.find((el) => el.id === id);
      this.$confirm(`Are you sure you want to delete customer ${customer.name}?`).then((res) => {
        if (!res) return;

        this.$requestHelpers
          .delete(`api/admin/customer/${id}`)
          .then(() => {
            this.customers = this.customers.filter((e) => e.id !== id);
          });
      });
    },

    createCompositeCustomer(customerId) {
      this.$requestHelpers
        .post('api/admin/compositeCustomer/createByCustomer',
          {
            customer_id: customerId,
          }).then((response) => {
          if (response.data.success) {
            this.setSnack({
              color: 'green',
              text: 'composite customer was created',
            });

            const customerIndex = this.customers
              .findIndex((el) => el.id === customerId);

            this.customers[customerIndex].has_same_name_composite_customer = true;
          }
        });
    },

    callBindTripbitsCustomerSetDialog(customerId, customerName) {
      Object.assign(
        this.bindTripbitsDialog,
        {
          isOpen: true,
          customerName,
          customerId,
          tripbitsId: null,
        },
      );
    },

    bindTripbitsCustomerSet() {
      this.$requestHelpers
        .post('api/admin/customer/bindTripbitsCustomerSet',
          {
            customer_id: this.bindTripbitsDialog.customerId,
            tripbits_id: this.bindTripbitsDialog.tripbitsId,
          }).then((response) => {
          const item = this.customers.find((el) => el.id === response.data.result.id);

          Object.assign(item, response.data.result);
        }).finally(() => {
          this.bindTripbitsDialog.isOpen = false;
        });
    },

    callUnbindTripbitsCustomerSetDialog(customerId, customerName, tripbitsId) {
      Object.assign(
        this.unbindTripbitsDialog,
        {
          isOpen: true,
          customerName,
          customerId,
          tripbitsId,
        },
      );
    },

    unbindTripbitsCustomerSet() {
      this.$requestHelpers
        .post('api/admin/customer/unbindTripbitsCustomerSet',
          {
            customer_id: this.unbindTripbitsDialog.customerId,
            tripbits_id: this.unbindTripbitsDialog.tripbitsId,
          }).then((response) => {
          const item = this.customers.find((el) => el.id === response.data.result.id);

          Object.assign(item, response.data.result);
        }).finally(() => {
          this.unbindTripbitsDialog.isOpen = false;
        });
    },

    fetchTripbitsCustomerSetList() {
      this.$requestHelpers
        .get('api/admin/customer/getTripbitsCustomerSetList')
        .then((response) => {
          this.tripbitsCustomerSets = response.data;
        });
    },
  },

  computed: {
    currentTripbitsCustomerSet() {
      return (id) => {
        const item = this.tripbitsCustomerSets.find((el) => el.id === id);
        return item || { id: null, name: 'n/a', routemap: null };
      };
    },
  },
};
</script>
